import React from "react";

const Policy = () => {
    return(
      <div className="container">
        <div className="wrapper">
          <h2>1. Use of Information We Collect About You</h2>
          <h3>The categories of information we collect can include:</h3>
          <p>a) Information You Provide. When you register, use or subscribe to any of our Services or take part in any
            interactive features of the Services (such as any contests, games, promotions, quizzes, surveys, research or
            other services or features), we may collect a variety of information, including: contact Information such as
            your name, email address, phone number, including mobile phone number, and correspondence address ("Contact
            Information"); and other Information such as birth date, photographs, videos, password, billing information,
            credit card information, demographic information, your personal interests and background, gender, age,
            dating gender preference, physical characteristics, sexual life experiences, children, ethnicity, religion,
            hobbies, political views and geographical location (including GPS). We may also receive information (for
            example, your email address) through other users, for example, if they have tried to refer SugarD to
            you.</p>
          <p>b) Information About How You Use the Services. We may collect information about your participation and
            actions on the Services. This may include information such as the pages and profiles you view, how you
            browse the Services, your SugarD coin balance and purchases you make through the Services such as boosts and
            gifts. It can also include the various functions and features that you use, such as SugarD Messenger, the
            connections you make, other friends or contacts you invite to the Services and profile searches you perform,
            or your use of our applications.</p>

          <p>c) Information from Content You Upload. When you upload photos or other content to the Services, we may
            collect information about the content, such as the time, date and place the photo or content was taken or
            uploaded, and how you use them, who views them or with whom you share.</p>
          <p>d) Information Automatically Collected. When you access our Services, we automatically record information
            from your device and its software, such as your IP address, browser type, Internet service provider,
            platform type, the site from which you came and the site to which you are going when you leave our website,
            date and time stamp and one or more cookies that may uniquely identify your browser or your account. When
            you access our Services using a mobile device, we may also receive or collect identification numbers
            associated with your device (such as a UDID), mobile carrier, device type and manufacturer, phone number,
            and, if you have enabled features and functions of the Services that use your device’s location, your
            geographical location data (including GPS). We also collect information about your usage of, and activity
            on, our Services. Also, some of the information we collect, for example an IP address, can sometimes be used
            to approximate a device's location.</p>
          <p>e) Cookies and Similar Technologies. We may use cookies and similar technologies, including those from
            third parties, to collect, analyze and store information and to provide and improve the Services. You can
            find out more about our use of cookies and similar technologies in our Cookie Policy.</p>
          <p>f) Your Communications with Us and Other Users. We collect communications you send to us (such as emails),
            comments that you post on the pages we have on a third-party service, such as our YouTube, Twitter and
            Facebook pages and messages that you send us directly through those services. We may also collect any
            comments, photos, messages or other content or communications you and other users share on the Services.</p>
          <p>g) Location Data. When you have enabled geographical location-based or GPS services on your device in
            relation to a TransMe mobile app or any TransMe features or services that use geographical
            location-based or GPS information, we will collect information about your geographical location or GPS
            position based on the location of the device you are using to access our Services.</p>
          <p>h) Information Provided by Others. We may be provided with information relating to you or your conduct on
            the Services by other users of the Services. We also collect information that third-party organizations may
            provide to us, such as contact details, demographic information, Internet navigation details, credit
            reference or fraud prevention information. Financial institutions or payment processors may also provide us
            updated payment information or payment methods related to your paid account or paid subscription.</p>
          <h2>2. Agreement on the Use of the Information on You.</h2>
          <h3>In General. We may use information that we collect about you to:</h3>
          <p>a) deliver and improve our products and services, and manage our business;</p>
          <p>b) manage your account and provide you with customer support;</p>
          <p>c) perform research and analysis about your use of, or interest in, our or others’ products, services, or
            content;</p>
          <p>d) communicate with you by email, postal mail, telephone and/or mobile devices about products or services
            that may be of interest to you either from us or other third parties;</p>
          <p>e) develop, display, and track content and advertising tailored to your interests on our Service and other
            sites, including providing our advertisements to you when you visit other sites;</p>
          <p>f) website or mobile application analytics;</p>
          <p>g) verify your eligibility and deliver prizes in connection with contests and sweepstakes;</p>
          <p>h) enforce or exercise any rights in our Terms of Use; and</p>
          <p>i) perform functions or services as otherwise described to you at the time of collection.</p>
          <h2>3. Personal Information Sharing</h2>
          <p>Information Shared with Other Users. When you register as a user of TransMe, your profile will be
            viewable by other users of the Service. Other users will be able to view information you have provided to us
            directly, any additional photos you upload, your first name, your age, approximate number of miles away,
            your personal description, and information you have in common with the person viewing your profile. If you
            swipe right to say "yes" to a person, you will be able to chat with that person if they swipe right to you,
            e.g., when you have a "match". If you chat with your match, of course your match will see the content of
            your chat.</p>
          <p>Personal information. We do not share your personal information with others except as indicated in this
            Privacy Policy or when we inform you and give you an opportunity to opt out of having your personal
            information shared. We may share personal information with:</p>
          <h2>4. Choice About the Collection and Use of Your Information</h2>
          <p>You can choose not to provide us with certain information, but that may result in you being unable to use
            certain features of our Service because such information may be required in order for you to register as
            user; purchase products or services; participate in a contest, promotion, survey, or sweepstakes; ask a
            question; or initiate other transactions.</p>
          <p>Our Service may also deliver notifications to your phone or mobile device. You can disable these
            notifications by deleting the relevant Service or by changing the settings on your mobile device.</p>
          <p>You can also control information collected by cookies. You can delete or decline cookies by changing your
            browser settings. Click "help" in the toolbar of most browsers for instructions.</p>
          <p>You can also block other users, topics and comments. That's meaning you don't see these contents any
            more.</p>
          <h2>5. Children's Privacy</h2>
          <p>Although our Service is a general audience Service, we restrict the use of our service to individuals age
            13 and above. We do not knowingly collect, maintain, or use personal information from children under the age
            of 13.</p>
          <h2>6. Protection on Your Personal Information</h2>
          <p>We take security measures to help safeguard your personal information from unauthorized access and
            disclosure. However, no system can be completely secure. Therefore, although we take steps to secure your
            information, we do not promise, and you should not expect, that your personal information, chats, or other
            communications will always remain secure. Users should also take care with how they handle and disclose
            their personal information and should avoid sending personal information through insecure email. Please
            refer to the Federal Trade Commission's website at <a
                href="http://www.ftc.gov/bcp/menus/consumer/data.shtm">http://www.ftc.gov/bcp/menus/consumer/data.shtm</a> for
            information about how to protect yourself against identity theft.</p>
          <h2>7. Compliance with "Children's Safety Standards" Policy</h2>
          <p>Compliance with "Children's Safety Standards" Policy
We are committed to ensuring that our application fully complies with "Children's Safety Standards" policy to protect children from any form of online harm. Therefore, we hereby declare the following:

In accordance with "Children's Safety Standards" policy, the application must meet the following requirements:</p>
          <h5>Publish Standards for Child Sexual Abuse and Exploitation (CSAE)</h5>
          <p>Our application has established and publicly published standards addressing child sexual abuse and exploitation (CSAE). We strictly prohibit any activities related to CSAE and take effective measures to prevent and respond to such incidents, ensuring the online safety of children.</p>
          <h5>Provide an In-App User Feedback Mechanism</h5>
          <p>Our application includes an in-app feedback system that allows users to report any potential issues or inappropriate behavior related to child safety. We actively review all feedback and take necessary actions based on the reports received.</p>
          <h5>Handle Child Sexual Abuse Material (CSAM)</h5>
          <p>We are committed to strictly managing and removing any Child Sexual Abuse Material (CSAM). Our application utilizes technological measures to detect, report, and remove any CSAM, in full compliance with relevant legal requirements.</p>
          <h5>Comply with Child Safety Laws</h5>
          <p>Our application fully complies with all applicable child protection laws, including but not limited to COPPA (Children's Online Privacy Protection Act) and other local regulations. We ensure that no sensitive personal information of minors is collected and take steps to protect children’s privacy and data security.</p>
          <h5>Provide a Contact for Child Safety Matters</h5>
          <p>We provide a clear contact point for child safety-related concerns. Users can contact us regarding any child safety issues through the following methods:

Email: myeasydating@gmail.com</p>
          <h2>8. Changes to Privacy Policy</h2>
          <p>We will occasionally update this Privacy Policy. When we post changes to this Privacy Policy, we will
            revise the "last updated" date at the top of this Privacy Policy. We recommend that you check our Service
            from time to time to inform yourself of any changes in this Privacy Policy or any of our other policies.</p>
          <h2>9. Disclaimer</h2>
          <p>Photos that contain lewness, nudity, child alone, sexual scene and other contents that are prohibited by
            law are also forbidden at TransMe. If any violation, your account will be banned and removed right
            away!</p>
        </div>
      </div>
    )
}

export default Policy;