import React from "react";
import ParticlesBg from "particles-bg";

const Home = () => {
    return(
      <div>
        <ParticlesBg type="lines" num={450} bg={true} />
        <div className="container valign-wrapper jc-center">
          <div className="valign center-align white-text">
            <h2>
              TRANSGENDER HUB
            </h2>

            <p className="big">
              TransMe is the best comminicate app for Transgender/Transsexual/Crossdress/Pansexual people.
              <br/>It's also a chat and hookup app for MTF, FTM, Tgirls, Non-binary people and other trans singles. 
              <br/>It's free to join our TS personals app to chat with other singles.
            </p>

            <div className="store-btn">
              <a className="btn waves-light waves-effect m-r-16" target="_blank" href="https://apps.apple.com/us/app/trans-dating/id1542897595?mt=8">
                  <i className="fab fa-app-store m-r-8"></i>
                  App store
              </a>
            </div>
            <div className="store-btn">
              <a className="btn waves-light waves-effect" target="_blank" href="https://play.google.com/store/apps/details?id=com.myeasydating.tger">
              <i className="fab fa-google-play m-r-8"></i>
                  Google play
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }


  export default Home;